import React from "react";
import menu from "../../Resources/all-in-one_menu.pdf";
import thumbnail from "../../Resources/menu_thumbnail.jpg";
import "./Menu.scss";

const Menu = () => {
  return (
    <div className="menu-container">
      <p className="menu-title">OUR MENU</p>
      <span className="underline"></span>
      <a className="menu-thumbnail" href={menu} target="_blank">
        <span className="view-menu-text">View Menu</span>
        <img src={thumbnail} />
      </a>
    </div>
  );
};

export default Menu;
