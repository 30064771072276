import "./App.css";
import Header from "./Components/sections/Header";
import Footer from "./Components/sections/Footer";
import Slide from "./Components/sections/Slide";
import MenuGrid from "./Components/sections/MenuGrid";
import Contact from "./Components/sections/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./Components/sections/Menu";

const App = () => {
  return (
    <div className="main-container">
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Slide></Slide>}></Route>
          <Route path="/menu" element={<Menu></Menu>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
};

export default App;
