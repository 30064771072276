import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <section className="contact-container">
      <iframe
        title="map"
        frameborder="0"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCLwnxV9GD8k66f639TqsSCXpl8JGoySJI&q=New+Asia+House,Warwick+RI&zoom=19"
      ></iframe>
      <div className="contact-info">
        <span className="contact-top">
          <p className="contact-title">New Asia House</p>

          <span className="contact-address">
            <p>300 Quakler Ln.</p>
            <p>Warwick, RI 02886</p>
            <p>401-826-8200</p>
          </span>

          <a
            href="https://www.google.com/maps/dir//New+Asia+House+300+Quaker+Ln+Warwick,+RI+02886/@41.68574,-71.4994632,19z/data=!4m5!4m4!1m0!1m2!1m1!1s0x89e44b6c97fe7c63:0x90dd352513bebd1a"
            className="direction-button"
          >
            {/* <p className="button-text">GET DIRECTION</p> */}
            GET DIRECTION
          </a>
        </span>
        <span className="contact-bot">
          <p className="contact-title">Business Hours</p>
          <span className="contact-hours">
            <p>Sun - Thurs: 11:00am - 10:00pm</p>
            <p>Fri - Sat: 11:00am - 11:00pm</p>
          </span>
        </span>
      </div>
    </section>
  );
};

export default Contact;
