import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../../Images/logo-hue.png";
import { Link } from "react-router-dom";
import useWindowDim from "../../hooks/useWindowDim";

const Header = () => {
  const { width, height } = useWindowDim();

  return (
    <section className="header-container">
      <Link to="/">
        <img src={logo} alt="New Asia House Logo" className="logo" />
      </Link>
      <nav className="navbar">
        <a
          href="https://order.online/business/new-asia-house-11308257"
          className="order-btn"
        >
          <p>order online</p>
        </a>
        <li className="links">
          <Link to="/">home</Link>
        </li>
        <li className="links">
          <Link to="/menu">menu</Link>
        </li>
        <li className="links">
          <a href="/contact">contact us</a>
        </li>
      </nav>
    </section>
  );
};

export default Header;
