import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer-container">
      <p>300 Quaker Ln. Warwick, RI 02886 / (401) 826-8200</p>
      <p>Designed by Ethan Wu</p>
    </section>
  );
};

export default Footer;
