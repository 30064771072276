import React, { useState, useEffect } from "react";

const getWindowDims = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDim = () => {
  const [windowDims, setWindowDims] = useState(getWindowDims());

  useEffect(() => {
    function handleResize() {
      setWindowDims(getWindowDims());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDims;
};

export default useWindowDim;
